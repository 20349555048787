.expertise-mgmt {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  flex-direction: column;
  background: #fff;
  .heading {
    color: #414141;
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: underline #f7911e;
  }
  .expertise-main {
    display: flex;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    .expertise-container {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: 33%;
      .logo {
        z-index: 1;
        margin-top: 150px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .sub-heading {
        z-index: 1;
        color: #fff;
        text-shadow: 0px 4px 107px rgba(0, 0, 0, 0.25);
        font-family: Outfit;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 5px;
      }
      .sub-description {
        z-index: 1;
        color: #fff;
        text-shadow: 0px 4px 107px rgba(0, 0, 0, 0.25);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px;
      }
      .clr {
        color: #fff;
      }
      .ylw-clr {
        color: #f7911e;
      }
    }
    .expertise-container::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        #d9d9d9 7.48%,
        rgba(0, 0, 0, 0.8) 95.17%,
        rgba(0, 0, 0, 0.8) 100%
      );
      mix-blend-mode: multiply;
    }
  }
}

@media (max-width: 1300px) {
  .expertise-mgmt {
    .heading {
      font-size: 40px;
    }
    .expertise-main {
      .expertise-container {
        .logo {
          margin-top: 70px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .sub-heading {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 22px;
          text-align: center;
        }
        .sub-description {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .expertise-mgmt {
    .heading {
      font-size: 25px;
    }
    .expertise-main {
      flex-direction: column;
      .expertise-container {
        padding-bottom: 30px;
        width: 100%;
        .logo {
          margin-top: 70px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .sub-heading {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 20px;
          text-align: center;
        }
        .sub-description {
          font-size: 12px;
        }
      }
    }
  }
}
