.form-container {
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  margin: auto;
}

.form {
  padding-right: 60px;
}
.form input:focus,
.form textarea:focus {
  border: 1px solid rgb(254, 129, 4);
}
.field-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}
.input-form {
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(192, 190, 190);
  outline: none;
  width: 100%;
  text-align: left;
  padding: 0px 2px 0px 10px;
}
.input-form-email {
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(192, 190, 190);
  outline: none;
  width: 100%;
  text-align: left;
  padding: 0px 2px 0px 10px;
}
.input-container {
  width: 48.5%;
}
.input-form-phone {
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(192, 190, 190);
  outline: none;
  width: 100%;
  text-align: left;
  padding: 0px 2px 0px 10px;
}
.input-textarea-form {
  border-radius: 5px;
  border: 1px solid rgb(192, 190, 190);
  outline: none;
  width: 100%;
  text-align: left;
  padding: 0px 2px 0px 10px;
}
.full-width {
  width: 100%;
}

.error-message {
  color: red;
}

.submit-btn {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 5px;
  // background-color: rgb(252, 112, 5);
  background: #2291c4;
  color: white;
  border: 2px solid transparent; /* Transparent border in the normal state */
  border-radius: 25px;
  // font-size: 20px;
  cursor: pointer;
  color: #fff8f8;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
  transition: background-color 0.3s, transform 0.3s, border-color 0.3s;
}

.submit-btn:active,
.submit-btn:hover {
  transform: scale(
    0.95
  ); /* Apply a slight scale down effect on press or hover */
  border-color: #2291c4; /* Orange border on hover or press */
  background-color: transparent; /* Transparent background on hover or press */
}

.submit-btn:hover {
  color: #2291c4;
}

@media screen and (max-width: 700px) {
  .input-container {
    width: 100%;
  }
}
