* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0px;
  left: 0px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.vision-section {
  padding: 50px;
  text-align: center;
}

.vision-primary-text {
  padding: 20px 15%;
  line-height: 24px;
  color: #303030;
}

.vision-knowmore-button {
  margin-top: 1.5rem;
  text-align: center;
  padding: 0.7rem 1.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.38);
  border-radius: 6px;
  border: none;
  font-weight: 700;
}

.carousel .thumbs {
  z-index: -1;
  display: none;
}

.carousel-status {
  display: none;
}

.vision-knowmore-button>span {
  background: linear-gradient(92.63deg, #e30e00 0%, #ffb800 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.vision-knowmore-button:hover {
  span {
    background: white;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  background: linear-gradient(95.14deg, #e30e00 0%, #ffb800 100%);
}

.vision-knowmore-button:focus {
  outline: none;
}

.advantages-section {
  background-color: #ffdb81;
  padding: 5%;
  text-align: center;
}

.advantage-items-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px;
  margin: 50px 0px;
}

.adv-text {
  text-align: center;
}

.adv-icon {
  margin-bottom: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.line-seperator {
  margin: 1.5rem 0rem;
  border-top: 1px solid;
  border-image: linear-gradient(45deg, red, orange) 1;
}

.products-container {
  padding: 5%;
  overflow: hidden;
  // margin: 1.5rem 0rem;
}

.products-heading {
  text-align: center;
}

.product-list-section {
  margin-top: 50px;
}

.products-list {
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 80px;
}

.products-list1 {
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 80px;
}

.product-image {}

.productImg {
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 400px;
  height: auto;
}

.product-heading {
  color: #1e1e1e;
  font-size: 1.5em;
  font-weight: 600;
  margin: 1.2rem 0rem;
}

.product-description-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.product-description {
  color: #1e1e1e;
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
}

.product-img {
  width: 70%;
  height: auto;
}

@media screen and (max-width: 1000px) {
  .vision-primary-text {
    padding: 10px 15%;
  }
}

@media screen and (max-width: 900px) {
  .products-list {
    grid-template-columns: 6fr 6fr;
    gap: 40px;
  }

  .products-list1 {
    grid-template-columns: 6fr 6fr;
    gap: 40px;
  }
}

@media screen and (max-width: 768px) {
  .products-list {
    display: grid;
    grid-template-columns: 12fr;
    margin: 2rem 0rem;
  }

  .products-list1 {
    grid-template-columns: 12fr;
    margin: 1rem 0rem;
  }

  .product-image {
    order: 1;
  }

  .product-description-section {
    order: 2;
  }

  // .vision-primary-text {
  //   padding: 10px;
  // }
}

@media screen and (max-width: 600px) {
  .adv-text {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .productImg {
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 250px;
    height: auto;
  }

  .vision-primary-text {
    padding: 10px 0px;
  }

  .advantage-items-section {
    display: grid;
    gap: 50px;
  }
}

@media screen and (max-width: 400px) {
  .advantages-heading {
    font-size: 1.5em;
    margin: 0rem 0.2rem;
  }

  .product-heading {
    font-size: 1.2em;
  }

  .adv-text {
    width: 100%;
  }

  .products-heading {
    font-size: 1.5em;
  }
}

.sliding-image {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  background-color: #409fdf;
}

.image-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.hero-section-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.carousel-heading {
  font-size: 3rem;
  font-family: sans-serif;
  color: white;
  width: auto;
}

.fade-in {
  animation: fadeInUp 1s ease-in-out 0.5s both;
}

.fade-in-heading {
  animation: fadeInUp 0.8s ease-in-out 0.5s both;
  /* Start with the same delay as the overlay fade-in */
}

.divider {
  margin: 10px auto;
  /* Adjust the spacing around the line */
  width: 200px;
  /* Set the width of the line */
  border: none;
  border-top: 2px solid rgb(255, 255, 255);
  /* Orange color for the line */
}

.fade-in-divider {
  animation: fadeInUp 0.8s ease-in-out 1.2s both;
  /* Delay divider by 1.2s after heading */
}

.fade-in-button {
  animation: fadeInUp 1s ease-in-out 1.4s both;
  /* Delay button by 1.4s after heading */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slider-btn {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  padding-bottom: 0.7rem;
  background-color: #f7911e;
  color: white;
  border: 2px solid transparent;
  /* Transparent border in the normal state */
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, border-color 0.3s;
}

.slider-btn:active,
.slider-btn:hover {
  transform: scale(0.95);
  /* Apply a slight scale down effect on press or hover */
  border-color: #f7911e;
  /* Orange border on hover or press */
  background-color: transparent;
  /* Transparent background on hover or press */
}

.slider-btn:hover {
  color: #f7911e;
}

.hero-section-heading-1 {
  color: white;
  font-size: 4.5em;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

.hero-section-heading-2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.hero-section-heading-2-1 {
  color: white;
  font-size: 4.5em;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 22px;
  width: 100%;
}

.hero-section-heading-2-2 {
  color: #f7911e;
  font-weight: 600;
  margin-right: 0.3rem;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

.hero-section-small-heading {
  color: white;
  font-weight: 600;
  font-size: 2em;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.hero-explore-button {
  margin-top: 2rem;
}

.offering-section {
  text-align: center;
  border: 0.2px solid rgba(175, 175, 175, 0.5);
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 23px 12px rgba(139, 132, 244, 0.2);
  width: 17rem;
  height: 12rem;
  padding: 1rem;
}

.skills-section {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0rem 2rem;
}

.skillsContent {
  font-weight: 500;
  margin-top: 0.5rem;
}

.middle-section {
  background: linear-gradient(283deg,
      rgba(97, 201, 248, 0.28) -2.92%,
      rgba(255, 255, 255, 0.47) 95.78%);
}

.services-section {
  margin: 2rem 3rem;
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-gap: 3rem;
}

.servicesImage {
  border-radius: 20px;
  width: 95%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.serives-content {
  margin-left: 3rem;
}

.services-heading {
  color: "#414141";
  font-size: 2.5em;
  font-weight: 700;
  border-bottom: 3px solid #ff8700;
  display: inline-block;
}

.services-quote {
  color: #6c6969;
  font-style: italic;
  font-size: 1.1em;
  font-weight: 400;
  margin-top: 1rem;
}

.services-breakups-one,
.services-breakups-two,
.services-breakups-three,
.services-breakups-four {
  background-color: white;
  border-radius: 7px;
  border-left: 13px solid #f7911e;
  padding: 1.2rem;
  color: #f7911e;
  font-weight: 700;
  height: max-content;

  .services-open-text {
    display: none;
  }
}

.services-breakups-one:hover,
.services-breakups-two:hover,
.services-breakups-three:hover,
.services-breakups-four:hover {
  // transform: scale(1.03);
  transition: all 0.23s 0s ease-in-out;

  .services-open-text {
    display: block;
  }
}

.services-breakup-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services-breakups-one:active,
.services-breakups-two:active,
.services-breakups-three:active,
.services-breakups-four:active {
  background-color: #f7911e;
  border-radius: 7px;
// @apply border-l-blue-600;
  padding: 1.2rem;
  // color: white;
  font-weight: 700;
  // width: 25rem;
}

.Services-container {
  padding: 2rem 0rem;
}

.services-img {
  position: relative;
  left: 2rem;
  bottom: 3rem;
}

.servce-breakup-text {
  text-align: center;
}

.service-breakup-part-1 {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-gap: 2rem;
}

.services-explore-button {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.services-open-text {
  color: #484848;
}

// .serviceImageContainer {
//   display: flex;
//   align-items: center;
// }

.serviceImageContainerMobile {
  display: none;
}

@media screen and (max-width: 1500px) {
  .hero-section-heading-1 {
    font-size: 4.1em;
  }

  .hero-section-heading-2-1 {
    font-size: 4.1em;
    font-weight: 600;
  }

  .hero-section-heading-2-2 {
    font-weight: 600;
  }

  .hero-section-small-heading {
    font-size: 1.8em;
  }

  .hero-explore-button {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .hero-section-heading-1 {
    font-size: 3em;
  }

  .hero-section-heading-2-1 {
    font-size: 3em;
    font-weight: 600;
  }

  .hero-section-heading-2-2 {
    font-weight: 600;
  }

  .hero-section-small-heading {
    font-size: 1.8em;
  }

  .hero-explore-button {
    margin-top: 2rem;
  }

  .services-section {
    grid-gap: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .serviceImageContainerMobile {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .carousel-heading {
    font-size: 2rem;
    font-family: sans-serif;
    color: white;
    width: auto;
  }

  .serviceImageContainer {
    display: none;
  }

  .hero-section-heading-1 {
    font-size: 2.5em;
  }

  .hero-section-heading-2-1 {
    font-size: 2.5em;
    font-weight: 600;
  }

  .hero-section-heading-2-2 {
    font-weight: 600;
  }

  .hero-section-small-heading {
    font-size: 1em;
    margin-top: 1rem;
  }

  .hero-explore-button {
    margin-top: 1rem;
    font-size: 1em;
  }

  .services-section {
    grid-template-columns: 12fr;
  }

  .serives-content {
    margin: 0rem;
  }

  .service-breakup-part-1 {
    grid-template-columns: 12fr;
  }
}

@media screen and (max-width: 500px) {
  .services-section {
    margin: 2rem 2rem;
  }

  .hero-section-heading-1 {
    font-size: 2em;
  }

  .hero-section-heading-2-1 {
    font-size: 2em;
    font-weight: 600;
  }

  .hero-section-heading-2-2 {
    font-weight: 600;
    line-height: 22px;
  }

  .hero-section-small-heading {
    font-size: 1em;
    margin-top: 1rem;
  }

  .hero-explore-button {
    margin-top: 1rem;
    font-size: 1em;
  }

  .hero-explore-button {
    margin-top: 3rem;
  }

  .offering-section {
    width: 20rem;
  }
}