.navbar-desktop-container {
  // background-color: #1E1E1E;
  background-color: rgba(0, 0, 0, 0.7);
  // background-color: rgba(36, 80, 105, 0.3);
  // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  z-index: 10;
}

.navbar-mobile-container {
  display: none;
}

.navbar-image-section {
  cursor: pointer;
}

.navbar-image {
  width: 160px;
  /* Set a width and/or height */
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  // max-width: 390px;
}

.navbar-services-section {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.3em;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: .5rem 0rem;
}

.navbar-mobile-services {
  padding: 0rem 1rem 0.5rem 1rem;
}

.service-item {
  padding: 12px;
  font-weight: 600;
  color: #ffffff;
  font-size: .9em;
  transition:0.3s;
  z-index: 2;
}

.service-item-mobile {
  padding: 12px;
  font-weight: 600;
  color: #ffffff;
  font-size: .9em;
  transition:0.3s;
  z-index: 2;
  font-size: 1.2em;
  font-weight: 500;
}

.service-item-mobile >  div {
  text-align: center;
}

.service-item:hover {
  color: #F7911E;
  cursor: pointer;
  transform:scale(1.045);
}

.search-icon {
  width: 30px;
  height: 30px;
  max-width: 25px;
  max-height: 25px;
}

.service-item-contact-us {
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  background: #F7911E;
  padding: .3rem 1rem;
  font-size: .8em;
  color: white;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition:0.3s;
}

.service-item-contact-us:hover {
  background: #ffb867;
  transform:scale(1.05);
}

.centerElement {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileCompanyLogo {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .navbar-desktop-container {
    display: none;
  }

  .navbar-mobile-container {
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 0 8px 6px -6px rgb(224, 224, 224);
    -moz-box-shadow: 0 8px 6px -6px rgb(224, 224, 224);
    box-shadow: 0px 10px 30px #00000062;
    padding: 1rem 0rem;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    z-index: 10;
  }

  .navbar-mobile-content {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
  }

  .service-item {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .navbar-image {
    max-width: 200px;
    // max-height: 120px;
  }
}