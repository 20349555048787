.submit-form-main {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 40px;

  height: auto;
  font-family: Arial, sans-serif;
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-column-gap: 20px;
  .heading {
    color: #ff8700;
    font-family: Outfit;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .sub-heading {
    width: auto;
    height: 98px;
    flex-shrink: 0;
    background: #2291c4;
    color: #fff;
    font-family: Outfit;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .description {
    color: #6c6969;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.54px;
  }
  .contact-us-main {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .number-container {
      display: flex;
      gap: 5px;
    }
  }
}
.text {
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.6px;
}
.drk {
  color: #000;
}
.lgt {
  color: #525252;
}
@media screen and (max-width: 1250px) {
  .text {
    font-family: Outfit;
    font-size: 16px;
  }
  .submit-form-main {
    grid-template-columns: auto;
    grid-row-gap: 40px;
    .contact-container {
      width: 80vw;
    }
    .sub-heading {
      font-size: 35px;
    }
    .description {
      font-size: 16px;
    }
    .contact-us-main {
      justify-content: center;
      gap: 50px;
      .number-container {
        display: flex;
        gap: 5px;
        div > img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .submit-form-main {
    .contact-us-main {
      flex-direction: column;
      .number-container {
        display: flex;
        gap: 5px;
        div > img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
