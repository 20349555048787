.footer_main {
  background: #1e1e1e;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer_logo {
    img.footer_company_logo {
      width: 200px;
      height: auto;
    }
    .footer_social_links{
      display: flex;
      align-items: center;
      img.footer_linkedin_logo {
        width: auto;
        height: auto;
        padding-right: 10px;
      }
    }
  }
  .sub_container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 35px;
    .footer-link-section {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .section-title {
        // color: rgba(255, 255, 255, 0.9);
        color:#ff8700;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        font-weight: bold;
        line-height: normal;
      }
      .links-container {
        list-style: none;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .anchor-link {
        text-decoration: none;
        color: #bbb;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .anchor-link:hover {
        color: #2291c4;
      }
    }
  }
}

.footer_main_copy_write {
  background: #ededed;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  .copy_write{
    font-size: 0.8rem;
    line-height: 1.4rem;
    color: #000;
    font-family: Montserrat-Light;
    display: block;
  }
  .footer_logo_copy_write {
    .footer_social_links{
      display: flex;
      align-items: center;
      img.footer_linkedin_logo {
        width: 80%;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .footer_main {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1100px) {
  .footer_main {
    flex-direction: column;
    gap: 20px;
    .sub_container {
      .footer-link-section {
        .section-title {
          font-size: 16px;
        }
        .anchor-link {
          font-size: 12px;
        }
      }
    }
  }
  .footer_main_copy_write{
    .copy_write{
      font-size: 0.75rem;
    }
    .footer_logo_copy_write {
      .footer_social_links{
        display: flex;
        align-items: center;
        img.footer_linkedin_logo {
          width: 85%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .footer_main {
    flex-direction: column;
    gap: 20px;
    .sub_container {
      grid-template-columns: auto auto;
      grid-row-gap: 30px;
      .footer-link-section {
        .section-title {
          font-size: 16px;
        }

        .anchor-link {
          font-size: 12px;
        }
      }
    }
  }
  .footer_main_copy_write{
    .copy_write{
      font-size: 0.75rem;
    }
    .footer_logo_copy_write {
      .footer_social_links{
        display: flex;
        align-items: center;
        img.footer_linkedin_logo {
          width: 75%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .footer_main_copy_write{
    .copy_write{
      font-size: 0.75rem;
    }
    .footer_logo_copy_write {
      .footer_social_links{
        display: flex;
        align-items: center;
        img.footer_linkedin_logo {
          width: 65%;
          height: auto;
        }
      }
    }
  }
}