.how-we-work-container {
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 5px;
  background: linear-gradient(
    283deg,
    rgba(255, 255, 255, 0) -2.92%,
    rgba(255, 255, 255, 0.28) 21.34%
  );

  .main-heading {
    color: #484848;
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: underline #f7911e;
  }

  .main-desc {
    color: #7e7c7c;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .steps-containers {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .step-container {
      width: 25%;
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 5px;
      position: relative;

      .circle {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Outfit;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &::before {
          content: "";
          width: 100%;
          height: 2px; /* Adjust the height (thickness) of the line as needed */
          background-color: #3f78e0; /* Line color */
          position: absolute;
          top: 45px; /* Vertically center the line within the circle */
          transform: translateY(-50%);
          left: 0;
          right: 0;
          z-index: -1;
        }
      }

      .step-heading {
        text-decoration: underline;
        color: #484848;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
        padding: 5px;
      }
      .step-description {
        color: #414141;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 5px;
      }
    }
  }
}

@media (max-width: 780px) {
  .how-we-work-container {
    padding-top: 0px;

    .main-heading {
      text-align: center;
    }

    .main-desc {
      text-align: center;
    }

    .steps-containers {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 40px;

      .step-container {
        width: 100%;
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: relative;

        .circle {
          background-color: #e0e9fa;
          width: 85px;
          height: 85px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          font-family: Outfit;
          font-size: 35px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &::before {
            content: "";
            width: 100%;
            height: 2px; /* Adjust the height (thickness) of the line as needed */
            background-color: #3f78e0; /* Line color */
            position: absolute;
            top: 45px; /* Vertically center the line within the circle */
            transform: translateY(-50%);
            left: 0;
            right: 0;
            z-index: -1;
          }
        }

        .step-heading {
          text-decoration: underline;
          color: #484848;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration-line: underline;
          padding: 5px;
        }
        .step-description {
          color: #414141;
          font-family: Outfit;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 5px;
        }
      }
    }
  }
}

.active {
  background-color: #3f78e0;
  color: #e0e9fa;
}

.in-active {
  background-color: #e0e9fa;
  color: #3f78e0;
}
